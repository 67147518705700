import { Component, ChangeDetectionStrategy, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { RouteParamsService } from "src/app/shared/services/essential/route-params.service";
import { MenuItem } from "../menu/menu.component";
import { UniconComponent } from "src/app/shared/components/unicon/unicon.component";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { RouterLink } from "@angular/router";
import { IsMenuSelectedPipePipe } from "./pipes/is-menu-selected.pipe";

@Component({
  selector: "app-school-menu",
  templateUrl: "./school-menu.component.html",
  styleUrls: ["./school-menu.component.scss"],
  standalone: true,
  imports: [NzMenuModule, UniconComponent, RouterLink, IsMenuSelectedPipePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchoolMenuComponent implements OnDestroy {
  idSchool: number = 0;
  idSub$?: Subscription;
  menuItemList?: MenuItem[];

  constructor(private paramsService: RouteParamsService) {
    this.idSub$ = this.paramsService.idSchool$.subscribe((idSchool) => {
      this.idSchool = idSchool ?? 0;
      this.menuItemList = this.getMenu();
    });
  }

  ngOnDestroy(): void {
    this.idSub$?.unsubscribe();
  }

  getMenu(): MenuItem[] {
    const root = ["/school", this.idSchool?.toString()];

    return [
      {
        title: "Escola",
        icon: "university",
        route: root,
        id: this.idSchool,
      },
      {
        title: "Alunos",
        icon: "users-alt",
        route: [...root, "student"],
        id: this.idSchool,
      },
      {
        title: "Professores",
        icon: "user-square",
        route: [...root, "professor"],
        id: this.idSchool,
      },
      {
        title: "Turmas",
        icon: "rocket",
        route: [...root, "class"],
        id: this.idSchool,
      },
      {
        title: "Grade Horária",
        icon: "calender",
        route: [...root, "agenda"],
        id: this.idSchool,
      },
    ];
  }
}
