import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UserInfo } from "src/app/shared/models/user/user-info.model";
import { LektoAuthService } from "src/app/shared/services/essential/lekto-auth.service";
import { Md5 } from "ts-md5";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { EssentialsModule } from "src/app/shared/modules/essentials.module";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "[app-user-menu]",
  templateUrl: "./user-menu.component.html",
  styleUrls: ["./user-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NzDropDownModule,
    NzAvatarModule,
    NzIconModule,
    NzMenuModule,
    EssentialsModule,
  ],
})
export class UserMenuComponent {
  userInfo: UserInfo;

  get userInitials() {
    if (!this.userInfo) return "";

    const splited = this.userInfo.name.trim().split(" ");

    if (splited.length > 1) {
      return `${splited[0].substring(0, 1)}${splited[splited.length - 1].substring(0, 1)}`;
    }

    return this.userInfo.name.substring(0, 2);
  }

  constructor(private auth: LektoAuthService) {
    this.userInfo = this.auth.getUserInfoData()!;
  }

  logout() {
    this.auth.logout();
  }

  get simplifiedName(): string {
    if (!this.userInfo) return "";

    const split = this.userInfo.name.split(" ");

    if (split.length > 1) {
      return `${split[0]}`;
    } else {
      return this.userInfo.name;
    }
  }

  public getAvatar() {
    if (!this.userInfo) return null;

    const picture = this.userInfo?.picture;

    if (!picture) {
      const mailHash = Md5.hashStr(this.userInfo.email);
      return `https://www.gravatar.com/avatar/${mailHash}?s=40&r=g&d=404`;
    } else {
      return picture;
    }
  }
}
