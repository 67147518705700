<div class="avatar">
  <a nz-dropdown [nzDropdownMenu]="menu">
    @if (userInfo) {
      <nz-avatar
        [nzAlt]="userInfo.name"
        [title]="userInfo.name"
        [nzText]="userInitials"
        [nzSrc]="getAvatar()" />
    }

    <span class="user-name" style="font-size: 16px">{{ simplifiedName | titlecase }}</span>

    <app-unicon iconName="angle-down" />
  </a>

  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu nzSelectable>
      <li nz-menu-item (click)="logout()">Sair</li>
    </ul>
  </nz-dropdown-menu>
</div>
