<header class="lekto-header">
  <div nz-row class="w-100" style="gap: 10px" nzAlign="middle" nzJustify="end">
    @if (isAdmin) {
      <div nz-col>
        <a href="{{ isAdmin ? '' : '/school' }}" title="Lekto">
          <img src="../../../assets/images/marca.png" alt="Lekto" />
        </a>
      </div>
    }
    <div nz-col nzFlex="auto">
      <app-menu />
    </div>
    <div nz-col class="text-right">
      <nz-space nzAlign="baseline">
        <app-faq *nzSpaceItem />
        <div app-user-menu *nzSpaceItem></div>
      </nz-space>
    </div>
  </div>
</header>
