import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UserMenuComponent } from "../user-menu/user-menu.component";
import { FaqComponent } from "../faq/faq.component";
import { MenuComponent } from "../menu/menu.component";
import { NzColDirective, NzRowDirective } from "ng-zorro-antd/grid";
import { NzSpaceComponent, NzSpaceItemDirective } from "ng-zorro-antd/space";
import { NzFlexDirective } from "ng-zorro-antd/flex";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MenuComponent,
    FaqComponent,
    UserMenuComponent,
    NzColDirective,
    NzRowDirective,
    NzSpaceComponent,
    NzFlexDirective,
    NzSpaceItemDirective,
  ],
})
export class HeaderComponent {
  @Input()
  public isAdmin = false;
}
