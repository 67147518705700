<header class="school-header">
  <nz-row nzAlign="middle">
    <nz-col nzFlex="auto">
      <div style="display: flex; align-items: center; gap: 16px">
        <h1 class="school-name">
          {{ schoolData?.txName }}
        </h1>

        @if (schoolData?.network) {
          <div>
            <nz-tag>
              <span class="school-network">
                {{ schoolData?.network?.txName }}
              </span>
            </nz-tag>

            @if (schoolData?.subNetwork) {
              <nz-tag>
                <span class="school-network">
                  {{
                    schoolData?.subNetwork?.coDistrict ??
                      schoolData?.subNetwork?.txName
                  }}
                </span>
              </nz-tag>
            }
          </div>
        }
      </div>
    </nz-col>

    <nz-col>
      <app-school-menu />
    </nz-col>
  </nz-row>
</header>
