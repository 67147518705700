<a nz-dropdown [nzDropdownMenu]="menu" class="faq" nzPlacement="bottomRight">
  <app-unicon iconName="question-circle" size="24px" color="#a3a3a3" />

  <span class="support-description menu-title">Central de Apoio</span>
</a>

<nz-dropdown-menu #menu>
  <ul nz-menu nzMode="inline">
    <li nz-menu-item class="faq-item">
      <app-unicon iconName="file-question-alt" class="icon-angles" />
      <a
        (click)="goToZendesk('https://suporte.lekto.com.br/hc/pt-br')"
        href="#"
        style="color: #181818">
        Acessar Dúvidas Frequentes
      </a>
    </li>

    <li nz-menu-item class="faq-item">
      <app-unicon iconName="whatsapp" class="icon-angles" />
      <a
        target="_blank"
        style="color: #181818"
        href="https://api.whatsapp.com/send?phone=551140037796"
        >Falar com Especialista Lekto</a
      >
    </li>

    <!-- </li> -->
    <li nz-menu-item class="faq-item">
      <app-unicon iconName="file-exclamation" class="icon-angles" />
      <a
        (click)="
          goToZendesk('https://suporte.lekto.com.br/hc/pt-br/requests/new')
        "
        style="color: #181818"
        href="#">
        Abrir um chamado
      </a>
    </li>

    <li nz-menu-item class="version">
      <span>Versão: {{ version }}</span>
    </li>

    @if (featureFlag) {
      <span class="version build"> Build: {{ buildVersion }} </span>
    }
  </ul>
</nz-dropdown-menu>
